import React, { useEffect, useState } from "react"
import Layout from "../layouts/default"
import Seo from "../components/seo"
import Back from "../components/back"
import Swiper from "../components/swiper"
import Image from "gatsby-plugin-sanity-image"

const ProjectPage = props => {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(window.matchMedia("(min-width: 512)"))
    }, [])

    const getYouTubeEmbeddeUrl = url => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = url.match(regExp)

        return match && match[2].length === 11
            ? `https://www.youtube.com/embed/${match[2]}`
            : url
    }

    return (
        <Layout className="project" hasHeader={false}>
            <Swiper back={`/${props.pageContext.parent.title}`}>
                <Seo title={props.pageContext.title} />
                {props.pageContext.collection.map(item => {
                    switch (item._type) {
                        case "artwork": {
                            return (
                                <Image
                                    key={item._key}
                                    {...item.image}
                                    alt={
                                        item.title
                                            ? item.title
                                            : "kuno.ist Artwork"
                                    }
                                    width={960}
                                    config={{
                                        format: "webp",
                                    }}
                                    style={{
                                        backgroundColor: "#000",
                                        objectFit: "contain",
                                        height:
                                            item.image.asset.height >
                                                item.image.asset.width &&
                                            !isMobile
                                                ? "100vh"
                                                : "auto",
                                        maxHeight:
                                            item.image.asset.height >
                                            item.image.asset.width
                                                ? "100vh"
                                                : "auto",
                                    }}
                                />
                            )
                        }
                        case "video": {
                            return (
                                <iframe
                                    key={item._key}
                                    width="512"
                                    height="512"
                                    src={getYouTubeEmbeddeUrl(item.video)}
                                    title="kuno.ist Video"
                                    allowFullScreen
                                    frameBorder={0}
                                />
                            )
                        }
                        case "content": {
                            return (
                                <div className="content" key={item._key}>
                                    {item.title && <h1>{item.title}</h1>}
                                    <p>{item.text}</p>
                                </div>
                            )
                        }
                        default: {
                            return <></>
                        }
                    }
                })}
                <Back to={`/${props.pageContext.parent.title}`} />
            </Swiper>
        </Layout>
    )
}

export default ProjectPage
